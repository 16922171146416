/**
 * Component dependencies.
 */

var events = require('events');
var scroll = require('scroll');



/**
 * Expose 'Directory' component.
 *
 * replaces default search form submission by not using query but using /<search-value> at the end of the action.
 * Action is the same page just with the search query at the end of it.
 *
 *
 * @param {Element} el
 * @api public
 */

module.exports = function(el, options) {
	var searchForm = el.querySelector('.directory-search form');
	var searchBar = el.querySelector('#search');
	var searchSubmit = el.querySelector('.submit-btn')
	var searchOverlay = el.querySelector('.search-overlay');
	var currentPage = searchForm.getAttribute('action');
	var inter = null;
	var interCount = 0;

	/**
	* Event Handlers
	* */
	events(searchBar, 'focus', function(ev){
		searchOverlay.classList.add('active');
		searchSubmit.classList.add('active');
		if (inter != null) {
			clearInterval(inter);
			interCount = 0;
		}
	});
	events(searchBar, 'blur', function(ev){
		searchOverlay.classList.remove('active');
		inter = setInterval(function(ev) {
			interCount++
			if (interCount === 2) {
				clearInterval(inter);
				searchSubmit.classList.remove('active');
				interCount = 0;
				inter = null;
			}
		}, 500);

	});
	events(searchForm, 'submit', function(ev) {
		ev.preventDefault();
		var query = searchBar.value;
		window.location = window.location.origin+currentPage.replace('.html', '.query-' + query + '.html');
	});

	/**
	 * Check if page loaded with a search and jump to results
	 *
	 * checking length greater than 1 because of the / after html can persist even without the search.
	 */
	if ($('.directory-places').data('searched') === true) {
		var directory = document.getElementById('qu-directory-container');
		scroll(directory.offsetTop);
	}



};
