/**
 * Module dependencies.
 */

var events = require('events');

/**
 * Initialize form input.
 *
 * This function is only called form inputs of type
 * file.
 *
 * @param {Element} el 
 * @api public 
 */

module.exports = function(el) {

  /**
   * Label to update with the file name.
   */
  
  var label = el.querySelector('.file-label');

  var input = el.querySelector('input');
  events(input, 'focus', function(e) {
    input.classList.add('focus');
  });

  events(input, 'blur', function(e) {
    input.classList.remove('focus');
  });

  /**
   * Listen for file selection.
   */
  
  el.addEventListener('change', function(e) {
    var value = e.target.value;
    var text = '';
    if(!value) {
      if(!label.classList.contains('no-file')) {
        label.classList.add('no-file');
      }
      text = 'No File Chosen';
    } else {
      text = value.split( '\\' ).pop();
      label.classList.remove('no-file');
    }
    label.innerHTML = text;
  });
};