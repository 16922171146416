
/**
 * Module dependencies.
 */

var $ = require('jquery');
var events = require('events');
var material = require('material');
var registry = require('./registry');
var dtm = require('dtm');
var results = require('./results.html');
var getlist = require("formget");



/**
 * Expose 'deanslist'
 */

module.exports = function(el, options) {
	var form = el.querySelector('form');
	var dropdown = populate(form.states);
	var path = form.getAttribute('action');
	var list = material(results, {hidden: true})
	  .attr('data-repeat', function(ul) {
			this.on('changed results', repeat.bind(null, ul))
	  })
	  .build()
	  .append(form, 'afterend');

	events(form, 'submit', function(e) {
		e.preventDefault();
		var url = path + '.' + dropdown.value + '.json';
		getlist(el, url, function(err, data) {
			var text = dropdown.options[dropdown.selectedIndex].text;
			var record = data.DeansList.Record;
			if(!record) list.set('error', options.norecord);
			else if(err || !record.length) {
				list.set('error', options.error);
			} else {
				list.set('state', text);
				list.set('results', record);
			}
			list.set('hidden', false);
			list.el.focus();
			dtm.set({
				'events': {
					'deansList': {
						'state': text.toLowerCase()
					}
				}
			});
			dtm.call('dtmDeansListSearch');
			$(".deanslist-results").blur();
		});
	});
};



/**
 * Create result items for selected state.
 *
 * Items are sorted alphabetically.
 * 
 * @param  {Element} el  
 * @param  {Array} data
 * @api private    
 */

function repeat(el, data) {
	var dictionnary = registry();
	el.innerHTML = '';
	var arr = data.sort(function(a, b) {
		return a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase());
	}).map(function(student) {
		var ul = dictionnary.register(student);
		ul.insertAdjacentHTML('beforeend', '<li>' + student.lastName + ', ' + student.firstName + ' ' +  student.middleName + '</li>')
	});
	dictionnary.group(deanslist.bind(null, el))
}


/**
 * Create deanslist and append it to element.
 * 
 * @param  {Element} el 
 * @param  {String} title 
 * @param  {Element} list  
 * @api private
 */

function deanslist(el, title, list) {
	var li = document.createElement('li');
	li.className = 'newrow';
	var h4 = document.createElement('h4');
	h4.className = 'b4 subheading';
	h4.innerHTML = title;
	li.appendChild(h4);
	li.appendChild(list);
	el.appendChild(li);
}


/**
 * Populate dropdown with list of states.
 *
 * An option is added at the end for international students.
 * 
 * @param  {Element} dropdown 
 * @return {Element}          
 * @api private
 */

function populate(dropdown) {
	states(function(state) {
		dropdown.add(new Option(state.text, state.value));
	});

	return dropdown;
}


/**
 * Return list of states.
 *
 * @param {Function} cb
 * @api private
 */

function states(cb) {
	$.getJSON('/api/tools/statelist.json').then(function(data) {
		data.push({
			text:'International Students',
			value:'international'
		});
		sort(data).map(cb);
	})
}


/**
 * Sort list of states.
 *
 * @param {Array} list
 * @return {Array}
 * @api private
 */

function sort(arr) {
	return arr.sort(function(a, b) {
		return a.text > b.text ? 1 : -1;
	});
}

