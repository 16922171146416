/**
 * Dependencies
 */

var events = require('events');
var material = require('material');
var post = require('formpost');
var dtm = require('dtm');


/**
 * Expose 'ugadmissionstatus'
 *
 * Get student status on submit
 *
 * @param {Element} el
 * @api public
 */

module.exports = function(el) {
  var url = el.getAttribute('action') + '.json';
  events(el, 'submit', function(event) {
  	event.preventDefault();
	if(el.checkValidity()) {
		dtm.set({
			'events' : {
				'admissionsCheck' : {
					'studentId' : el.querySelector('input[name="id"]').value,
					'zipCode': el.querySelector('input[name="zip"]').value
				}
			}
		});
		post(el, url, success.bind(el));
	} else el.classList.add('invalid');
  });
};


/**
 * Create application status on submit success.
 * 
 * @param  {Object} data 
 * @api private
 */

function success(err, data) {
  var el = this;
  if(err) {
    el.classList.add('wrong-information');
    el.querySelector('input[name="id"]').focus();
  } else {
  	dtm.set('events.admissionsCheck.status', data.responseStatus);
  	dtm.call('dtmAdmissionsCheck');

    el.parentElement.classList.add('submitted');
    var tmpl = el.querySelector('.ugadmissionstatus-template');
    el.parentNode.replaceChild(
      material(tmpl, data).build().el,
      el
    );
  }
}