/**
 * Navigation dependencies.
 */

var events = require('events');


/**
 * Secondary navigation scroll.
 * 
 * @param  {Element} el 
 * @api public    
 */

module.exports = function(el) {

	var navigation = el.querySelector('.secondarynavigation');

	/**
	 * Initial position.
	 */
	
	var position = el.offsetTop;

	/*
	 * Recalculate top position on resize.
	 * @todo  deounce event
	 */
	
	events(window, 'resize', function() {
		position = el.offsetTop;
		sticky();
	});


	/**
	 * The secondary navigation is sticky on scroll and also
	 * slide with the main navigation bar.
	 */
	
	events(window, 'scroll', sticky);


	/**
	 * Sticky secondary nav position.
	 * 
	 * @api private
	 */
	
	function sticky() {
		setTimeout(function() {
			position = el.offsetTop;
			var emergencyBanner = document.getElementById("site-alert");
			var height = emergencyBanner.clientHeight;
			if (emergencyBanner.classList.contains("has-icon")) {
				position = position + height;
			}
		}, 1000);
		if(window.pageYOffset > position) navigation.classList.add('sticky');
		else navigation.classList.remove('sticky');
	}

};