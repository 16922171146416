
/**
 * Gallery dependencies.
 */

var debug = require('debug')('gallery');

/**
 * Expose 'Carousel'
 */

module.exports = Carousel;


/**
 * Create carousel with DOM elements..
 *
 * A carousel is made of multiple elements with one
 * element active at a time.
 */

function Carousel(parent) {
	// we should not need a query selector
	this.parent = parent.querySelector('.gallery-overflow');
	this.children = [];
	this.active = 0;
}



/**
 * Add slide in carousel.
 *
 * @param {Element} child
 * @return {this}
 * @api public
 */

Carousel.prototype.add = function(child) {
	this.children.push(child);
	return this;
};


/**
 * Retrieve index of a slide.
 *
 * @param {Element} child
 * @return {Number}
 * @api public
 */

Carousel.prototype.index = function(child) {
	return this.children.indexOf(child);
};


/**
 * Active next slide.
 *
 * @param {Boolean} bool
 * @return this
 * @api public
 */

Carousel.prototype.next = function(bool) {
	return this.show(this.active + 1, bool);
};


/**
 * Active previous slide.
 * 
 * @param {Boolean} bool
 * @return this
 * @api public
 */

Carousel.prototype.prev = function(bool) {
	return this.show(this.active - 1, bool);
};


/**
 * Show slide at given position.
 *
 * @param {Number} position
 * @param {Boolean} bool (apply transition)
 * @return this
 * @api public
 */

Carousel.prototype.show = function(position, bool) {
	var length = this.children.length;
	if(position < 0) position = length - 1;
	this.children[this.active].setAttribute('aria-hidden', true);
	this.active = position % length;
	debug('show slide ' + this.active);
	var current = this.children[this.active];
	current.setAttribute('aria-hidden', false);
	if(!bool) transform(this.parent, current);
	return this;
};


/**
 * Update carousel position.
 *
 * @return {this}
 * @api public     
 */

Carousel.prototype.update = function() {
	if(this.parent && this.children[this.active]) {
		transform(this.parent, this.children[this.active]);
	}
	return this;
};


/**
 * Get bounding rects of a node from its parent
 * element and translate the parent to show the active
 * element.
 * 
 * @param  {Element} parent 
 * @param  {Element} child  
 * @api private     
 */

function transform(parent, child) {
	var translate = child.getBoundingClientRect().left  - parent.getBoundingClientRect().left;
	parent.setAttribute('style', 'transform:translate3d(-' + translate + 'px,0,0);');
}



