/**
 * Scroll dependencies.
 */


var raf = require('raf');


/**
 * Expose `scrollTo`.
 */

module.exports = scrollBy;


/**
 * Scroll by `(y)`.
 *
 * @param {Number} to
 * @api public
 */

function scrollBy(to, duration) {
  var duration = duration || 1000;
  var from = position();
  var start = Date.now();
  var id;


  /**
   * Update scroll position.
   * 
   * @api private
   */
  
  function update() {
    var delta = Date.now() - start;
    if(delta >= duration) {
      if(id) raf.cancel(id);
      return;
    }
    window.scrollTo(0, from + (to - from) * outCirc(delta/duration));
  }


  (function animate() {
    id = raf(animate);
    update();
  })();
}


/**
 * Return scroll position.
 *
 * @return {Number}
 * @api private
 */

function position() {
  return window.pageYOffset || document.documentElement.scrollTop;
}


/**
 * outCirc ease function.
 * 
 * @param  {Number} n 
 * @return {Number}   
 * @api private
 */

function outCirc(n){
  n *= 2
  if (n < 1) return -0.5 * (Math.sqrt(1 - n * n) - 1);
  return 0.5 * (Math.sqrt(1 - (n -= 2) * n) + 1);
};