/**
 * Timeline dependencies.
 */

var material = require('material');
var scroll = require('scroll');
var debug = require('debug')('timeline');


/**
 * Expose 'timeline'
 * 
 * @api public
 */

module.exports = function(el) {
  
  var timeline = material(el);


  /**
   * Listen click on timeline jumper and
   * scroll page.
   */
  
  timeline.listen('click [aria-labelledby]', function(e) {
    var target = e.target;
  	timeline.show(target.getAttribute('aria-labelledby'));
  });


  /**
   * Add section in scroll timeline.
   *
   * A section has to contain an id as well as
   * an aria-label attribute labelling the section.
   *
   * @param {Element | NodeList} section
   * @api public
   */
  
  timeline.add = function(section) {
  	var label = section.getAttribute('aria-label');
  	var id = section.id;
  	if(label && id) {
  		jumper(el, label, id);
  		debug('add', label);
  	}
  	return this;
  };


  /**
   * Show section.
   *
   * @param {Number} id
   * @api public
   */
  
  timeline.show = function(id) {
  	var section = document.getElementById(id);
  	if(section) {
  		scroll(section.offsetTop);
  		debug('show');
  	}
  	return this;
  };


  /**
   * Init timeline with all sections
   * in a page.
   */
  
  (function init() {
  	var nodes = document.querySelectorAll('.section[aria-label]');
  	for(var i = 0, l = nodes.length; i < l; i++) {
  		timeline.add(nodes[i]);
  	}
  })();

  return timeline;
};


/**
 * Create timeline jumper.
 * 
 * @param  {Element} el   
 * @param  {String} label
 * @param {String} id
 * @api private    
 */

function jumper(el, label, id) {
  el.insertAdjacentHTML('beforeend', '<label><input type="radio" name="section-jumper"/><a class="section-jumper" type="radio" title="' + label + '" aria-labelledby="' + id + '"></a></label>');
}

