

/**
 * [exports description]
 * @return {[type]} [description]
 */

module.exports = function() {

  var that = {};

  var registry = that.registry = {};

  var cache;


  that.register = function(student) {
  	var letter = student.lastName.toLowerCase()[0];
  	if(!registry[letter]) {
  		if(cache) {
  			registry[letter] = cache;
  			cache = null;
  		} else {
  			cache = document.createElement('ul')
  			registry[letter] = cache;
  		}
  	}
  	return registry[letter];
  };


  that.group = function(cb) {
  	var keys = Object.keys(registry).sort();
  	keys.map(function(key, index) {
  	  if(!(index % 2)) {
  	  	cb(title(key, keys[index + 1]), registry[key]);
  	  }
  	});
  };


  function title(key, next) {
  	if(next) key += '-' + next;
  	return key;
  }

  return that;
};