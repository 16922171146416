
/**
 * Component dependencies
 */

var events = require('events');
var Dots = require('./dots');
var debug = require('debug')('section');
var focus = require('focus');
var listen = require('events');
var debounce = require('utility').debounceFunc;


/**
 * Only one dot navigation per page.
 * @type {Dots}
 */

var navigation = (function() {
  var dots;
  return function() {
    if(!dots) {
      debug('dot navigation created');
      dots = new Dots();
    }
    return dots;
  };
})();


/**
 * Sections offset.
 * @type {Array}
 */

var offsets = [];



/**
 * Add section element to dot navigation.
 *
 * This function is called for every section component
 * dropped into a page.
 * 
 * @param  {Element} el 
 * @api public   
 */

module.exports = function(el) {
  var id = el.getAttribute('aria-labelledby');
  if(id) {
    var title = document.getElementById(id);
    if(title) {
      navigation().add(id, title.innerHTML);
      offsets.push(el.offsetTop - 120);
    }

    /**
     * Update dot navigation on scroll.
     */
    var section = focus(el);
    section.on('focus', function(bool) {
      var pos = position(el);
      if(bool){
        navigation().select(pos);
      }

    });
    /**
     * Update dot navigation on scroll if we are outside sections to be focused for dot nav.
     */
    section.on('refocus', function(bool, region) {
      if(bool && region === 'top'){
        navigation().select(0);
      } else if (bool && region === 'bottom') {
        var sections = document.querySelectorAll('.qu-section[data-require]');
        navigation().select(sections.length-1);
      }

    });
  }


};


/**
 * Get selected section index based on element.
 *
 * @param {Object} el
 *
 * @return {Number}
 * @api private
 */

function position(el) {
  var sections = document.querySelectorAll('.qu-section[data-require]');
  for (var i = 0; i < sections.length; i ++){
    if(el.getAttribute('aria-labelledby') === sections[i].getAttribute('aria-labelledby')){
      return i;
    }
  }
}


/**
 * Find position for dot nav based on y position on load.
 *
 * @return {Number}
 * @api private
 */
function loadPosition() {
  var y = window.scrollY;
  var cache = 0;
  for(var i = 0, l = offsets.length; i < l; i++) {
    if(y > offsets[i] && y < offsets[i+1]) {
      cache = i;
    }
  }
  return cache;
}


//find position on load and set dot nav
listen(window, 'load', function() {
  var loadPos = loadPosition();
  navigation().select(loadPos);
});
