
/**
 * Module dependencies.
 */

var tmpl = require('template');
var Model = require('model');
var util = require('utility');
var events = require('events');


/**
 * Expose 'material'.
 */

module.exports = function(el, data, anchor, location) {
	if(data instanceof Element) {
		anchor = data;
		location = anchor;
		data = null;
	}
	var ui = new Material(util.dom(el), data);
	if(anchor) ui.append(anchor, location);
  return ui;
};


/**
 * UI layer.
 *
 * Examples:
 * 
 * @param  {Function} cb 
 * @param  {Model} data
 * @param {Element} anchor
 * @api public
 */

function Material(el, data) {
  Model.call(this, data);
  this.el = el;
}


// mixin prototypes
util.mixin(Material.prototype, Model.prototype);


/**
 * Bind attribute with function.
 *
 * Examples:
 *
 *   material.attr('required', function(input, content) {
 *     // do some validation on input elements
 *   });
 *
 * @param {String} name
 * @param {Function} cb
 * @return {this}
 * @api public
 */

Material.prototype.attr = function(name, cb) {
	var els = this.el.querySelectorAll('[' + name + ']');
	for(var i = 0, l = els.length; i < l; i++) {
		cb.call(this, els[i], els[i].getAttribute(name));
	}
	return this;
};


/**
 * Parse and build material DOM
 * element using parametric replacements.
 *
 * Examples:
 *
 *   var ui = material('<button>#{name}</button>', {
 *     name: 'olivier'
 *   }).build();
 *   // => <button>olivier</button>
 *
 *   ui.set('name, 'french');
 *   // => <button>french</button>
 * 
 * @return {this}
 * @api public
 */

Material.prototype.build = function() {
	var that = this;
	util.walk(this.el, function(node) {
		if(node.nodeType === 1) {
		  var attrs = node.attributes;
		  for(var i = 0, l = attrs.length; i < l; i++) {
		    that.bind(attrs[i]);
		  }
		} else {
		  that.bind(node);
		}
	});
	return this;
};



/**
 * Bind DOM element with data. This is also
 * called parametric replacement.
 *
 * @return {Element} node
 * @api private
 */

Material.prototype.bind = function(node) {
	var val = node.nodeValue;
	if(!/(\#|\!)\{([^{}]*)\}/.test(val)) return;

	var data = this.data;
	var compiled = tmpl(val, data);
	var keys = compiled[1];
	var fn = function() {
	  node.nodeValue = compiled[0](data);
	};
	fn();
	for(var l = keys.length; l--;) {
	  this.on('changed ' + keys[l], fn);
	}
	return this;
};


/**
 * Append material DOM into
 * document.
 *
 * This handler accept DOM elements, query 
 * selector string and insert locations.
 *
 * Examples:
 *
 *   ui.append(document.body);
 *   ui.append('.list');
 *   ui.append('.list', 'beforeend');
 * 
 * @return {this}
 * @api public
 */

Material.prototype.append = function(query, location) {
	util.insert(query, this.el, location);
	return this;
};


/**
 * Listen events on material dom
 * element.
 *
 * Examples:
 *
 *   ui.listen('click', doSomething);
 *
 *   // event delegation
 *   ui.listen('click .item', doSomething)
 * 
 * @param {String} selector
 * @param {Function?} cb
 * @return {Stream}
 * @api public
 */

Material.prototype.listen = function(selector, cb) {
	events(this.el, selector, cb);
	return this;
};


/**
 * Use plugins.
 * 
 * Examples:
 *
 *   ui.use(plugin, 'something');
 * 
 * @param  {Function} fn 
 * @return {this}
 * @api public
 */

Material.prototype.use = function() {
	fn.apply(this, [].slice.call(arguments, 1));
	return this;
};

