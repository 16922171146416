/**
 * Transform array-like object into array.
 *
 * @param {Object} obj
 * @return {Array}
 * @api public
 */

module.exports.toArray = function(obj) {
  return [].slice.call(obj);
};



/**
 * Mixin an object with an other one.
 *
 * @param {Object} obj
 * @param {Object} proto
 * @return {Object}
 * @api private
 */

module.exports.mixin = function(obj, proto) {
  for (var key in proto) {
    obj[key] = proto[key];
  }
  return obj;
};


/**
 * Walk dom tree recursively.
 *
 * It traverses dom elements
 * (type 1) as well as text
 * node (type 3).
 * 
 * @param {ELement} node
 * @param {Function} cb
 * @api public
 */

module.exports.walk = function walk(node, cb) {
  cb(node);
  node = node.firstChild;
  while (node) {
    walk(node, cb);
    node = node.nextSibling;
  }
};


/**
 * Template compiler.
 *
 * The compiler argument can be a query selector, a HTML
 * string, a DOM element or a function. It returns
 * a function that once executed return a DOM element.
 *
 * Examples:
 *
 *   var cb = template('.carousel');
 *   var cb = template('<button>hello</button>');
 *   var cb = template(document.body);
 *
 *   cb();
 *
 * @param {String | Element | Function} str
 * @param {Boolean} clone
 * @return {Element}
 */

module.exports.dom = function(str, clone) {
  var type = typeof str;
  var el = str;
  if(type === 'string') el = string(el);
  // else if(type === 'function') el = external(el);
  if(clone) el = el.cloneNode(true);
  return el;
};


/**
 * Parse template as string.
 * 
 * @param  {String} str 
 * @return {Element}
 * @api private     
 */

function string(str) {
  var el;
  if(str[0] === '<') {
    var div = document.createElement('div');
    div.innerHTML = str;
    el = div.firstChild;
  } else {
    el = document.querySelector(str);
  }
  return el;
}


/**
 * Insert adjacent Element shim.
 * 
 * @param  {Element} el
 * @param  {String} location 
 * @api public     
 */

module.exports.insert = function(parent, el, location) {
  switch(location) {
    case 'afterend':
      parent.parentNode.insertBefore(el, parent.nextSibling);
      break;
    case 'afterbegin':
    parent.insertBefore(el, parent.firstChild);
      break;
    case 'beforebegin':
      parent.parentNode.insertBefore(el, parent);
      break;
    default:
      parent.appendChild(el);
  }
};


/**
 * Debounce function calls
 *
 * @param  {Function} fn
 * @param  {Integer} delay
 * @api public
 */


module.exports.debounceFunc = function(fn, delay) {
  var timer = null;
  return function () {
    var context = this;
    var args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

