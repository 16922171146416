//Dependencies

var Handlebars = require('handlebars');


/**
 * Expose 'calendar-feed'
 */

module.exports = function (el, options) {
    if (el.classList.contains('calendar-feed')) {
        calendar($('#event-filters').html());
    }
};


/**
 * Function to begin the creation of the calendar and call to the localist api to get the events
 * @param filters - Optional filters to specify which event types are required
 */
function calendar(filters) {
    var fString = filterString(filters);
    var today = new Date();
    var year = today.getFullYear(); 
    var month = today.getMonth() + 1; 
    var day = today.getDate();
    var start = year + '-' + month + '-' + day;
    var days = '120';
    $.ajax({
        url: 'https://calendar.quinnipiac.edu/api/2.0/events?start=' + start + '&days=' + days + fString,
        method: 'GET',
        async: true,
        success: function (data) {
            var $eventHook = $('#event-content');
            data.events.slice(0,4).map(function(item) {
                 $eventHook.append(createEventTile(item));
            });
        }
    });
}
/**
 * Function to parse the array of filters and create a string to be added to the ajax call in the case that optional
 * filters have been included.
 * @param filters
 * @returns {string}
 */
function filterString(filters) {
    var filterArray = filters.split(',');
    var filterString = '';
    for (var a = 0, b = filterArray.length; a < b; a++) {
        if (filterArray[a] !== 'n/a' && filterArray[a]) {
            filterString = filterString + '&' + 'type=' + filterArray[a];
        }
    }
    return filterString;
}

/**
 * Function that calls to the Handlebars template to create the individual event tiles.
 * @param item
 * @returns {*}
 */
function createEventTile(item) {
    var src = $('#event-template').html(); 
    var monthArray = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUNE', 'JULY', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']; 
    var template = Handlebars.compile(src); 
    var eventTypes = item.event.filters.event_types;
    var dateInstance = item.event.event_instances[0].event_instance; 
    var startDate = new Date(dateInstance.start);
    var templateData = {
        color: 'white',
        dateColor: 'blue',
        image: item.event.photo_url,
        date: monthArray[startDate.getMonth()] + ' ' + startDate.getDate(),
        startTime: "",
        heading: item.event.title,
        location: item.event.location_name,
        linkURL: item.event.localist_url
    };
    var y = (eventTypes) ? eventTypes.length : 0;
    for (var x = 0; x < y; x++) {
        var type = eventTypes[x];
        if (type.name === "Academic Calendar") {
            templateData.color = "blue";
            templateData.dateColor = "navy";
        }
    }
    if (dateInstance.all_day === false) {
        templateData.startTime = startTime(startDate);
    }
    return template(templateData);
}

/**
 * function to parse out the time for inclusion in the date box for events that are not selected as "All day" in the
 * JSON
 * @param startDate
 * @returns {string}
 */
function startTime(startDate) {
    var hour = startDate.getHours();
    var ampm = "AM";
    if (hour >= 12) {
        hour = hour - 12;
        ampm = "PM";
    }
    if (hour === 0) {
        hour = 12;
    }
    return "@ " + hour + " " + ampm;

}
