
/**
 * Component dependencies.
 */

var debug = require('debug')('form');
var events = require('events');
var once = require('once');
var dtm = require('dtm');
var array = require('utility').toArray;


/**
 * Is mobile
 * @type {Boolean}
 */

var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream


/**
 * Expose 'form'.
 * 
 * @api public
 */

module.exports = function(el, data) {

  /**
   * Form status.
   */
  
  var status = 'valid';
  var formAction = data.form.actionType;
  ;


  /**
   * Group fields.
   * The attribute validation message is used to add validation constraints.
   */
  
  var groups = array(el.querySelectorAll('.group-field[validationmessage]'));

    //var gRecaptcha = document.getElementsByClassName('g-recaptcha');


  /**
   * Listen group fields changes once.
   */
  
  var check = once(listen);


  /**
   * Check form validity on submit.
   */

    /*events(el, 'submit', function(e) {
        e.preventDefault();
        status = 'valid';
        check(groups);
        groups.map(validate);
        if(!el.checkValidity() || status === 'invalid') {
            status = 'invalid';
            el.classList.add(status);
            e.preventDefault();
            el.querySelector('input:first-of-type:invalid').focus();
            debug('invalid');
            grecaptcha.reset();
            if(iOS) scroll(el);
        } else {
            console.log("got here to validated function");
            grecaptcha.execute();
            dtmDirectCall();
        }

    });*/
  events(el, 'submit', function(e) {
      e.preventDefault();
      status = 'valid';
      check(groups);
      groups.map(validate);
      if(!el.checkValidity() || status === 'invalid') {
          status = 'invalid';
          el.classList.add(status);
          e.preventDefault();
          el.querySelector('input:first-of-type:invalid').focus();
          debug('invalid');
          grecaptcha.reset();
          if(iOS) scroll(el);
      } else {
          console.log("got here to validated function");
          grecaptcha.execute();
          dtmDirectCall();
      }

  });


  /**
   * Check validity group fields.
   * 
   * @param  {Element} group 
   * @api private       
   */
  
  function validate(group) {
    var tmp;
    var type = group.getAttribute('validationmessage');
    if(type === 'select') {
      var input = group.querySelector('select');
      if(!input.checkValidity()) tmp = 'invalid';
    } else {
      var input = group.querySelector('input:checked');
      if(!input) tmp = 'invalid';
    }
    if(tmp) {
      group.classList.add('invalid');
      status = tmp;
      debug('group invalid');
    } else {
      group.classList.remove('invalid');
    }
  }


  /**
   * Listen changes on group fields to change
   * validity on the fly.
   * 
   * @param  {Element} group 
   * @api private      
   */
  
  function listen(groups) {
    groups.map(function(group) {
      events(group, 'change', function(e) {
        validate(group);
      });
    });
  }


  /**
   * send DTM direct call to record the form action
   *
   * @api private      
   */
  function dtmDirectCall() {
    var action = formAction.toLowerCase();
    var record = 'unknown';

    if(action) {
      if(action === 'email') {
        record = 'dtmEmailFormSubmit';
      } else if(action === 'data') {
        record = 'dtmDataSubmit';
      } else if(action === 'emailanddata') {
        record = 'dtmDataAndEmailSubmit';
      }
      dtm.call(record);
    }
  }


};


/**
 * Scroll page to first invalid input.
 *
 * @param {Element} form
 * 
 * @ticket QU-884
 * @api private
 */

function scroll(form) {
  var input = form.querySelector('input:invalid, .group-field.invalid');
  window.scrollTo(0, input.offsetTop);
}

