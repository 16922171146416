/**
 * Gallery dependencies.
 */

var Gallery = require('./carousel');
var events = require('events');
var debug = require('debug')('gallery');
var material = require('material');
var modal = require('./modal')
var focus = require('focus');
var array = require('utility').toArray;


/**
 * Expose 'gallery'
 */

module.exports = function(el, options) {

    /**
     * Create gallery.
     */
    
    var gallery = new Gallery(el);



    /**
     * Add slides to gallery.
     */
    
    var slides = el.querySelectorAll('.slide');
    var length = slides.length;
    array(slides).map(gallery.add.bind(gallery));

    /**
     * Create Modal
     */
    var lightbox = modal(el, length);


    /**
     * Bind slide index and gallery length
     * with DOM element.
     */
    var ui = material(el, {
        index: -1,
        length: length
    }).build();



    /**
     * Update lightbox whenever the actice slide
     * in the carousel changes.
     */
    
    ui.on('changed index', function(idx) {
        var slide = gallery.children[idx];
        if(slide) {
            lightbox.update(slide.querySelector('img'), idx);
        }
    });


    /**
     * Keyboard events should work only when gallery
     * is focused.
     */
    
    var section = focus(el);
    var focused = false;
    section.on('focus', function(bool) {
        focused = bool;
        if(!bool) lightbox.close();
    });

    section.on('resize', function() {
        lightbox.close();
        gallery.update();
    });


    var overflow = el.querySelector('.gallery-overflow');
    var xPos = 0;
    events(overflow, 'touchstart', function(e) {
        xPos = e.touches[0].pageX;
    });

    var currentPos;
    events(overflow, 'touchmove', function(e) {
        currentPos = e.touches[0].pageX;
        var left = gallery
          .children[gallery.active]
          .getBoundingClientRect().left;
        overflow.setAttribute('style', 'transition:none;transform:translate3d(-' + (left - currentPos + xPos - overflow.getBoundingClientRect().left) + 'px,0,0);');
        e.preventDefault();
    });

    events(overflow, 'touchend', function(e) {
        if(Math.abs(currentPos - xPos) < 80) {
            gallery.show(gallery.active);
            return;
        }
        show(currentPos > xPos ? 37 : 39);
    });



    /**
     * Listen events and update slide position.
     *
     * Events can be click on arrows, click on slides or
     * keyboard events (arrow left and right).
     */
    
    events(document, 'keyup', function(event) {
        var code = event.keyCode;
        if(!focused) return;
        if(code === 13) lightbox.show(gallery.active);
        else if(code === 27) lightbox.close();
        else show(event.keyCode);
    });

    events(el, 'click .icon-arrow', function(event, target) {
        var type = target.getAttribute('aria-label');
        show(type === 'previous' ? 37 : 39);
    });

    events(el, 'click .slide', function(event, target) {
        if(event.target.nodeName === 'IMG') {
            var idx = gallery.index(target);
            gallery.show(idx);
            ui.set('index', gallery.active);
            lightbox.show(gallery.active);
        }
    });
    //add gallery swipe to change image.
    var modalContent = el.querySelector('.gallery-modal picture');
    var modalStartPos;
    events(modalContent, 'touchmove', function(e){
        modalStartPos = e.touches[0].pageX;
        e.preventDefault();
    });
    events(modalContent, 'touchend', function(e) {
        if(Math.abs(modalStartPos - xPos) < 80) {
            gallery.show(gallery.active);
            return;
        }
        show(modalStartPos > xPos ? 37 : 39);
    });

    /**
     * Show slide.
     * 
     * @param  {Number} code
     * @api private
     */
    
    function show(code) {
        var idx = gallery.active;
        if(code === 37 && idx) gallery.prev();
        else if(code === 39 && idx < (length - 1)) gallery.next();
        ui.set('index', gallery.active);
    }

    //force progress bar to show proper position on load.
    ui.set('index', 0);
};