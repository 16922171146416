/**
 * Homepage dependencies.
 */

var focus = require('focus');
var scrollTo = require('scroll');
var events = require('events');


/**
 * Play video backgrounds on focus.
 *
 * @param  {Element} video
 * @api public
 */

module.exports = function(el) {

	var carrot = el.querySelector('.section-jump');

	/**
	 * Replay video when in focus if paused when coming into focus
	 */
	if(el.querySelectorAll('video').length > 0) {
		var hero = focus(el);
		hero.on('focus', function (bool) {
			var videoPlayer = el.querySelectorAll('.resp-media-video video')[0];
			if (!bool) {
				//pause video
				if (!videoPlayer.paused) {
					videoPlayer.pause();
				}
			} else {
				//play video if we are not playing already
				if (videoPlayer.paused) {
					videoPlayer.play();
				}
			}
		});
		hero.on('refocus', function(bool) {
			var videoPlayer = el.querySelectorAll('.resp-media-video video')[0];
			if(!bool) {
				//pause video
				if (!videoPlayer.paused){
					videoPlayer.pause();
				}
			} else {
				//play video if we are not playing already

				if (videoPlayer.paused) {
					videoPlayer.play();
				}
			}
		});
	}

	events(carrot, 'click', function() {
		var secondSection = document.querySelector('.content').querySelectorAll('.parbase')[0].nextElementSibling;
		scrollTo(secondSection.offsetTop);
	});
};