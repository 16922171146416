
/**
 * Module dependencies.
 */

var debug = require('debug');
var apply = Function.prototype.apply;


// allows to be load using data-require
module.exports = function() {};


/**
 * Timestamp.
 */

var timestamp;



/**
 * Debug messages decorator.
 * Listen debug logs and print in console.
 */

debug.on('log', function(name, msg) {
  var curr = new Date;
  var ms = curr - (timestamp || curr);
  timestamp = curr;
  msg = name 
    + ' : '
    + msg
    + ' +' + humanize(ms);
  window.console && apply.call(console.log, console, [msg]);
});



/**
 * Humanize the given `ms`.
 *
 * @param {Number} m
 * @return {String}
 * @api private
 */

function humanize(ms) {
  var sec = 1000;
  var min = 60 * 1000;
  var hour = 60 * min;

  if (ms >= hour) return (ms / hour).toFixed(1) + 'h';
  if (ms >= min) return (ms / min).toFixed(1) + 'm';
  if (ms >= sec) return (ms / sec | 0) + 's';
  return ms + 'ms';
}