var events = require('events');
var dtm = require('dtm');


/**
 * Expose 'social-share' component.
 *
 * This will open a new window when a social item is clicked to
 * the share page of said service. This new window will either
 * scrape for OG info on the URL provided or will scrape after
 * the user has submitted the post.
 *
 * @param {Element} el
 * @api public
 */

module.exports = function(el, options) {
	var facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u='+encodeURI(window.location);
	var twitterUrl = 'https://twitter.com/share?url='+encodeURI(window.location);
	var linkedInUrl = 'https://www.linkedin.com/shareArticle?mini=true&url='+encodeURI(window.location);

	var dtmLayer = {
		"events": {
			"social": {
				"network":'default',
				"interaction":'share'
			}
		}
	}
	//handle each share case and pass through proper info.
	events(el, 'click .share-link', function(e, ele){
		e.preventDefault();
		switch(true){
			case ele.classList.contains('share-email'):
				dtmLayer.events.social.network = 'email';
				dtm.set(dtmLayer);
				emailShare(ele);
				break;
			case ele.classList.contains('share-facebook'):
				dtmLayer.events.social.network = 'facebook';
				dtm.set(dtmLayer);
				openShareWindow(facebookUrl);
				break;
			case ele.classList.contains('share-linked-in'):
				dtmLayer.events.social.network = 'linked-in';
				dtm.set(dtmLayer);
				openShareWindow(linkedInUrl);
				break;
			case ele.classList.contains('share-twitter'):
				dtmLayer.events.social.network = 'twitter';
				dtm.set(dtmLayer);
				openShareWindow(twitterUrl);
				break;
			default:
				//no default currently.
				break;
		}

	});

	/**
	 *
	 * prompts email client functionality with pre populated info and URL.
	 *
	 * @param {Element} el
	 * @api private
	 *
	 * */
	function emailShare(el) {
		var head = document.querySelector('head');
		var bodyCopy, subject;
		var metas = head.getElementsByTagName('meta');

		//collect og info for use in email.
		for(var m = 0; m < metas.length; m ++){
			if (metas[m].getAttribute('property') === 'og:title') {
				subject= metas[m].getAttribute('content');
			} else if(metas[m].getAttribute('property') === 'og:description') {
				bodyCopy = metas[m].getAttribute('content');
			}
		}
		//generate email link info based on OG information.
		var emailLink = 'mailto:?subject=' +
			encodeURI(subject) +
			'&body=' +
			encodeURI(bodyCopy) +
			'%0D%0D' +
			encodeURI(window.location);



		//fire off open email
		window.location.href= emailLink;

		//fireDTM for email
		dtm.call('dtmSocialInteraction');

	}

	/**
	 *
	 * Open a new window based on share URL passed.
	 *
	 * @param {String} URL
	 * @api private
	 *
	 * */
	function openShareWindow(url) {
		var width = 650,
			height = 450;

		var windowOptions = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width='+width+',height='+height+',top='+(screen.height/2-height/2)+',left='+(screen.width/2-width/2);
		window.open(url, 'Share Window', windowOptions);

		//fireDtm call
		dtm.call('dtmSocialInteraction');
	}
}
