

/**
 * Expose 'jQuery'
 *
 * Allows to run code in isolation and maintain
 * it separately (without the AEM jQuery).
 * 
 * @type {jQuery}
 */

module.exports = jQuery;