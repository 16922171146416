
/**
 * Google street view dependencies.
 */

var lazy = require('lazy');
var events = require('events');
var panorama = require('./panorama');


/**
 * Expose 'google street view'
 *
 * Load panorama with first location and listen updates.
 */

module.exports = lazy(function(el, options) {
	geocode(options.coordinates, function(result) {
		el.classList.add('panorama-ready');
		options.position = {
			lat: result.lat,
			lng: result.lng
		};
		var view = panorama(el, options);
		//view.panning(true);
		listen(el, view);
	});
});


/**
 * Listen events on panorama.
 * 
 * @param  {Element} el   
 * @param  {Panorama} view 
 * @api private
 */

function listen(el, view) {
	events(el, 'click address', function(event, target) {
		geocode(target.getAttribute('position'), function(result) {
			var povHead = target.getAttribute('povHead');
			var povPitch = target.getAttribute('povPitch');
			view.position(result.lat, result.lng);

			if(povHead && povPitch) {
				view.pov({
					"heading" : parseFloat(povHead),
					"pitch": parseFloat(povPitch)
				});
			}
		});
	});

    // setTimeout(function() {
    // 	events(el.querySelector('.gm-compass-icon.gm-compass-needle'), 'click', function(event, target) {
    // 		var bool = target.getAttribute('data-panning') === 'true';
    // 		//view.panning(bool);
    // 		target.setAttribute('data-panning', !bool);
    // 	});
    // }, 1500);
}


/**
 * Geocode string coordinates.
 *
 * Examples:
 *
 *   geocode('10.44,-12', cb);
 *   geocode('17Ave SW Calgart', cb);
 * 
 * @param  {String} coordinates
 * @param  {Function} cb 
 * @return {Object}
 * @api private             
 */

function geocode(coordinates, cb) {
	var matches = coordinates.match(/^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$/);
	if(matches) {
		cb({
			lat: parseFloat(matches[1]),
			lng: parseFloat(matches[3])
		});
	} else {
		var geocoder = new google.maps.Geocoder();
		geocoder.geocode({
			'address' : coordinates
		}, function(results) {
			var location = results[0].geometry.location;
			cb({
				lat: location.lat(),
				lng: location.lng()
			})
		});
	}
}



