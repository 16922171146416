/**
 * Component dependencies.
 */

var events = require('events');



/**
 * Expose 'alerts' component.
 *
 * The element passed to the navigation component
 * Should have classes identifying each element that needs to be populated with the content from AJAX call.
 * AJAX call needs a random generated key to cache bust.
 *
 * @param {Element} el
 * @api public
 */

module.exports = function(el, options) {
	var cacheBust = Math.round(new Date().getTime() / 1000);
	var url = '/content/qu/global/settings/jcr:content/alerts.json?cb='+cacheBust;
	/* elements */
	var closeBtn = el.querySelector('.close-alert'),
			alertTitle = el.querySelector('.alert-title'),
			alertMessage = el.querySelector('.alert-message'),
			alertLink = el.querySelector('.alert-link'),
			alertIcon = el.querySelector('.alert-icon');
	var htmlBody = document.body;

	$.getJSON( url )
		.done( function( responseData ) {
			if(responseData.enabled && !checkMessageContent(responseData)) {
				//lets fill the alert area with content
				if(responseData.title != undefined){
					alertTitle.innerHTML = responseData.title;
				} else {
					alertTitle.classList.add('hide');
				}
				if(responseData.message != undefined){
					alertMessage.innerHTML = responseData.message;
				} else {
					alertMessage.classList.add('hide');
				}
				if(responseData.iconPath != undefined){
					alertIcon.setAttribute('src', responseData.iconPath);
					el.classList.add('has-icon');
				} else {
					alertIcon.classList.add('hide');
				}

				if(responseData.linkUrl != undefined){

					var linkCopy = responseData.linkText != undefined ? responseData.linkText : 'Link Copy not Set but the URL is';
					var linkTarget = responseData.linkTarget != undefined ? responseData.linkTarget : '';
					var backgroundClass = responseData.backgroundColor ? responseData.backgroundColor : '';

					if (backgroundClass){
						el.classList.add(backgroundClass);
						el.classList.remove('default-alert-theme');
					}

					alertLink.querySelector('span').innerHTML = linkCopy;
					alertLink.setAttribute('target', linkTarget);
					alertLink.setAttribute('href', responseData.linkUrl);
					
				} else {
					alertLink.classList.add('hide');
				}

				//add the class to show the alert area
				htmlBody.classList.add('alerts-enabled');
				$('.qu-article-top .article-breadcrumb').removeClass('article-breadcrumb-noalert');
			}
			else{
				$('.qu-article-top .article-breadcrumb').addClass('article-breadcrumb-noalert');
			}
			
		})
		.fail (function (jqxhr, textStatus, error) {
			//ajax failed don't do anything.

		});

	events(closeBtn, 'click', function(ev) {
		el.classList.add('hide');
		htmlBody.classList.remove('alerts-enabled');
		var alertTitle = el.querySelector('.alert-title').innerHTML;
		var alertMessage = el.querySelector('.alert-message').innerHTML;
		var alertLinkText = el.querySelector('.alert-link span').innerHTML;
		var alertLinkURL = el.querySelector('.alert-link').getAttribute('href');
		var cookieMessage = alertTitle + alertMessage + alertLinkText + alertLinkURL;
		document.cookie = "QUAlert=" + encodeURIComponent(cookieMessage) + "; path=/";
		$('.qu-article-top .article-breadcrumb').addClass('article-breadcrumb-noalert');
        sticky();
	});
};

/**
 * Checks if the cookie message and the current alert message are the same.
 * @param message - The current Alert Message
 * @returns {boolean} True if they are equal
 */
function checkMessageContent(response) {
	var title = response.title ? response.title : '';
	var message = response.message ? response.message : '';
	var linkText = response.linkText ? response.linkText : '';
	var linkURL = response.linkUrl ? response.linkUrl : '';
	var combinedMessage = title + message + linkText + linkURL;
	var cookieMessage = getCookie('QUAlert');
	return encodeURIComponent(combinedMessage) === cookieMessage;
}

function sticky() {
    var secondaryNavigation = document.getElementById("secondary-outer");
	var position = secondaryNavigation.offsetTop;
	var emergencyBanner = document.getElementById("site-alert");
    var height = emergencyBanner.clientHeight;
	if (emergencyBanner.classList.contains("has-icon")) {
		position = position + height;
	}
    if(window.pageYOffset > position) secondaryNavigation.classList.add('sticky');
    else secondaryNavigation.classList.remove('sticky');
}

/**
 * Generic function to retrieve a cookie
 * @param cname - The name of the cookie
 * @returns {*} - The Cookie
 */
function getCookie(cname) {
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') c = c.substring(1);
		if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
	}
	return "";
}
