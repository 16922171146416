/**
 * coursefinder dependencies.
 */

var events = require('events');
var scroll = require('scroll');

/**
 * Expose 'course-finder' component.
 *
 * @param {Element} el
 * @api public
 */

module.exports = function(el) {


        sortDivs('#courseresultsview');

        groupDivs('#courseresultsview');


        /**
         * Check if page loaded with a search and jump to results
         *
         */

        if ($('.qu-coursefinder.searchForm').data('searched') === true) {

            var results = document.getElementById('results');
            scroll(results.offsetTop);

        }

      // Replace asteriks with space in the results
      $(".courseresults span").each(function() {
          var text = $(this).text();
          text = text.replace(/\*/g, ' ');
         $(this).text(text);
       });


    /**
     * Click on btnSearchCourses button.
     */

    events(el, 'click #btnSearchCourses', function (e) {


       // get the values of the subject, session, and keywords
        var searchForm = el.querySelector('.qu-coursefinder form');
        var keywords = el.querySelector('#keywords');
        var subjects = el.querySelector('#coursefindersubjects');
        var sessions = el.querySelector('#coursefindersessions');
        var online = el.querySelector('#online');


        var searchSubmit = el.querySelector('#btnSearchCourses');
        var searchOverlay = el.querySelector('.courseFinderListView');
        var currentPage = searchForm.getAttribute('action');
        var inter = null;
        var interCount = 0;



        /**
         * Event Handlers
         * */
        events(keywords, 'focus', function(ev){
            searchOverlay.classList.add('active');
            searchSubmit.classList.add('active');
            if (inter != null) {
                clearInterval(inter);
                interCount = 0;
            }
        });
        events(keywords, 'blur', function(ev){
            searchOverlay.classList.remove('active');
            inter = setInterval(function(ev) {
                interCount++;
                if (interCount === 2) {
                    clearInterval(inter);
                    searchSubmit.classList.remove('active');
                    interCount = 0;
                    inter = null;
                }
            }, 500);

        });

        // update the url
        events(searchForm, 'submit', function(ev) {
            ev.preventDefault();

            //  path-to-page/course-finder.subject-<subject>.term-<term>.online-<bool>.query-<query>.html

            // Don't show default values in the URL
            var query = '';
            if (keywords.value){
                query = '.query-' + keywords.value;
            }
            var sub = '';
            if (subjects.value && subjects.value != "allsubjects"){
                sub = '.subject-' + subjects.value;
            }
            var term = '';
            if (sessions.value && sessions.value != "allsessions"){
                term = '.term-' + sessions.value;
            }
            var onl = '';
            if (online.checked){
                onl = '.online-true';
            }
            // remove the '.html' form currentPage
           var  truncatedPage = currentPage.slice(0, -5);

            window.location = window.location.origin+truncatedPage + sub + term + query + onl + '.search.html';

        });


    });


    /**
     * Sorts divs alphabetically
     * @param el the id of the wrapper div
     */
    function sortDivs(el) {

        var mylist = $(el);

        var listitems = mylist.children('div').get();

        listitems.sort(function (a, b) {
            return $(a).text().toUpperCase().localeCompare($(b).text().toUpperCase());
        });

        $.each(listitems, function (index, item) {
            mylist.append(item);
        });

    }

    /**
     * Group divs by course name
     * @param el the id of the wrapper div
     */
    function groupDivs(el) {

        var mylist = $(el);

        var listitems = mylist.children('div').get();

        var nextLevel = "";

        $.each(listitems, function (index, item) {

           var currentLead = $(this).data("sort");

            if (currentLead) {
                var courseType = currentLead.substring(0, currentLead.indexOf("*"));
                var courseNumber= currentLead.substring(currentLead.indexOf("*") + 1, currentLead.length);
                var courseLevel = courseNumber.charAt(0);

                if (courseLevel == nextLevel) {
                   // do nothing

               } else {

                   var headingwrapper=$('<p class="heading">'+ courseType + ' Level ' + courseLevel + '00 Courses </p>');
                   $(this).before(headingwrapper);
                   nextLevel = courseLevel;
               }

            }


        });

        // For some reason this function is being called twice (probably because this template is being initialized twice)
        // so let's clean up empty rows
        $.each($('#courseresultsview p'), function (index, item) {


           // if there's a heading below this one, remove this
            if ($(this).next().hasClass('heading')) {
              $(this).remove();
            }

        });


        // now let's group them into a div
        // This function is called multiple times
        // so let's not run this if there are coursegroup divs already
        if($(document).find('div.coursegroup').length === 0) {

        $('#courseresultsview p.heading').each(function (index, item) {

            if (index % 2 != 1) {
                $(this).nextUntil("p").andSelf().wrapAll('<div class="coursegroup odd" />');
            } else {
                $(this).nextUntil("p").andSelf().wrapAll('<div class="coursegroup" />');
            }

        });

        }



    }


};
