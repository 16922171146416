/**
 * DTM dependencies.
 */
var debug = require('debug')('dtm');
var events = require('events');
var debounce = require('utility').debounceFunc;


/**
 * DTM data layer.
 * The data layer is set as a global variable in an inline script
 * located in the base template.
 * @type {Object}
 */

var data = window.dtmDataLayerQU;

/**
 * Expose 'dtm'
 *
 * Examples:
 *
 *   var dtm = require('dtm');
 *   dtm.set('name', 'quinnipiac');
 */

var dtm = module.exports = {
	/**
	    * Set data layer object.
	    *
	    * Examples:
	    *
	    *   dtm.set('name', 'quinnipiac');
	    *   dtm.set({
   *     name: 'quinnipiac',
   *     version: '1.0'
   *   });
	    *   dtm.set('event.form', 'hello');
	    *
	    * @param {String|Object} key
	    * @param {Any} value
	    * @api private
	    */

	set: function (key, value) {
		var obj = key;
		if (typeof key === 'string') obj = toObject(key, value);
		merge(data, obj);
	},
	/**
	 * get path.
	 * example:
	 *   dtm.path('country'); //get
	 *   dtm.path('country.canada') //get;
	 *
	 * @param  {String} key
	 * @api public
	 */
	get: function (key) {
		debug('getting value for key ' + key);
		var path = key.split('.'),
			attr = data;

		try {
			for (var i = 0, l = path.length; i < l; i++) {
				attr = attr[path[i]];
			}
			return attr;
		} catch (e) {
			debug('Cannot get value for key "' + key + '"');
		}

	},


	/**
	 * DTM direct call by string name.
	 * @param {Sting} directCallString
	 *
	 * */
	call: function (directCallString) {
		if (typeof _satellite === 'undefined') { // Check for satellite
			debug('ERROR –– satellite.js isn\'t on the page!');
			return false;
		} else {
			_satellite.track(directCallString);
			debug('Direct call of ' + directCallString + ' made');

		}

	}
}


/**
 * Merge two objects together.
 *
 * @param  {Object} proto
 * @param  {Object} obj  
 * @return {Object} (proto object)    
 * @api private
 */

function merge(proto, obj) {
	for (var key in obj) {
		var value = obj[key];
		if (typeof value === 'object' && proto[key]) {
			debug('merging object at key ' + key);
			merge(proto[key], value);
		} else {
			var debugValue = typeof value === 'object' ? JSON.stringify(value) : value;
			debug('setting key ' + key + ' with value of ' + debugValue);
			proto[key] = value;
		}
	}
	return proto;
}

/**
 * Transform a path string into an object with
 * a given value.
 *
 * @param  {String} key  
 * @param  {Any} value
 * @return {Object}      
 * @api private
 */

function toObject(key, value) {
	var path = key.split('.'); // break out the keys into an array
	var newObj = {};
	var cacheObj = newObj;

	var setObj = function (key, value) {
		cacheObj[key] = value;

		if (typeof value === 'object') {
			cacheObj = cacheObj[key];
		}
	}

	for (var p = 0; p < path.length; p++) {
		if (p + 1 === path.length) {
			setObj(path[p], value)
		} else {
			setObj(path[p], {});
		}
	}

	return newObj;
}


/**
 * Looks at current breakpoint and old breakpoint. If different it updates DTM breakpoint value in data layer
 *
 * @api private
 */

function setBreakPoint() {
	var breakPoint = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
	if (dtm.get('page.pageInfo.responsiveBreakpoint') != breakPoint) {
		dtm.set('page.pageInfo.responsiveBreakpoint', breakPoint);
	}
}

//initial breakpoint set
if (dtm.get('page.pageInfo.responsiveBreakpoint') === 'default') {
	setBreakPoint();
}

//set DTM breakpoint on  resize
events(window, 'resize', debounce(setBreakPoint, 500));

