
/**
 * Module dependencies.
 */

var Emitter = require('emitter');
// var debug = require('debug')('model');


/**
 * Expose 'Model'
 */

module.exports = Model;


/**
 * Model layer.
 *
 * @param {Object} data
 * @api public
 */

function Model(data) {
  this.data = data || {};
}


// mixin emitter functions
Emitter(Model.prototype);


/**
 * Set key value.
 *
 * Examples:
 *
 *   model.set('name', 'olivier');
 * 
 * @param {String | Object | Stream} key 
 * @param {Any | Stream} value
 * @return {this}
 * @api public
 */

Model.prototype.set = function(key, value) {
	var prev = this.data[key];
	if(prev !== value) {
		this.data[key] = value;
		this.emit('changed', key, value, prev);
		this.emit('changed ' + key, value, prev);
	}
	return this;
};



/**
 * Get value.
 *
 * Examples:
 *
 *   model.get('name');
 *   // => olivier
 * 
 * @param {String} key 
 * @return {this}
 * @api public
 */

Model.prototype.get = function(key) {
	return this.data[key];
};



/**
 * Get value.
 *
 * Examples:
 *
 *   model
 *     .compute('name')
 *     .pipe(transform).
 * 
 * @param {String} key 
 * @param {Function?} cb
 * @return {Stream}
 * @api public
 */

Model.prototype.compute = function(key, cb) {
	var str = cb.toString();
	var attrs = str.match(/this.[a-zA-Z0-9]*/g);
	this.set(key, cb.call(this.data));
	for(var l = attrs.length; l--;) {
		this.on('changed' + attrs[l].slice(5), function() {
			this.set(name, cb.call(this.data));
		});
	}
};
