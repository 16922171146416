
/**
 * Component dependencies.
 */

var events = require('events');



/**
 * Expose 'navigation' component.
 *
 * The element passed to the navigation component
 * should have a burger menu.
 *
 * @param {Element} el
 * @api public
 */

module.exports = function(el) {

	var form = el.querySelector('form');
	if(form) {
		var searchInput = form.querySelector('input[type="text"]#q');
		var searchBtn = form.querySelector('#search-submit');
	}
	
	var closeBurger = el.querySelector('.close-burger');
	var overlay = el.querySelector('.navigation-overlay');
	var headerOverlay = document.querySelector('.header-overlay');

	var pageContent = document.body.querySelector('main.main');
	var leftDrawer = document.getElementById('drawer-left');
	var rightDrawer = document.getElementById('drawer-right');

	events(headerOverlay, 'click', function(){
		resetSearch();
	});

	events(closeBurger, 'click', function(){
		resetSearch();
	});

	if(searchInput) {
		events(searchInput, 'focus', function () {
			overlay.setAttribute('aria-hidden', 'false');
			pageContent.setAttribute('aria-hidden', 'true');
		});
	}
	// whenever an item in the form blurs we want to call the reset
	if(form) {
		form.addEventListener('blur', function (e) {
			//we do a timeout, as the document.activeElement is set after the blur function due to accessibility JS below
			setTimeout(function (e) {
				resetSearch();
			}, 500);
		}, true);
	}
	if(searchBtn) {
		events(searchBtn, 'focus', function (e) {
			overlay.setAttribute('aria-hidden', 'false');
			pageContent.setAttribute('aria-hidden', 'true');
		});
	}
	if(searchInput) {
		events(searchInput, 'keydown', function (e) {
			var keycode = e.keyCode || e.which;
			if (keycode === 9 || keycode === 27) {
				resetSearch();
			}
		});
	}
	if(overlay) {
		events(overlay, 'click', function () {
			resetSearch();
		});
	}

	//Remove Active state from cta links after they have been clicked, client ask
	events(el, "click .navigation .cta", function(ev, el){
		el.blur();
	});


	//not using events library as it doesn't support useCapture param. This will force focus to the drawer for accessibility tabing
	document.addEventListener('focus', function(e) {
		var drawerOpen = document.body.classList.contains('slide-drawer-left') ? true : (document.body.classList.contains('slide-drawer-right') ? true : false);
		var drawerSide = document.body.classList.contains('slide-drawer-left') ? document.getElementById('drawer-left') : (document.body.classList.contains('slide-drawer-right') ? document.getElementById('drawer-right') : false );

		//if we are in the left drawer, ensure we stay there
		if(drawerOpen && (typeof drawerSide === 'object')) {
			if(drawerSide === leftDrawer && !leftDrawer.contains(e.target)){
				if(e.target != el.querySelector('.close-burger')){
					e.preventDefault();
					e.stopPropagation();
					leftDrawer.focus();

				}
				//if we are in the right drawer, ensure we stay there
			} else if(drawerSide === rightDrawer && !rightDrawer.contains(e.target)){
				if(e.target != el.querySelector('.close-schools')){
					e.preventDefault();
					e.stopPropagation();
					rightDrawer.focus();
				}

			}

		}


	}, true);
	
	function resetSearch(){
		//only reset if the active element is not one of the form inputs
		if(document.activeElement != searchBtn && document.activeElement != searchInput){
			searchInput.value = '';
			overlay.setAttribute('aria-hidden', 'true');
			pageContent.setAttribute('aria-hidden', 'false');
		}
	}
	
	(function(){
		var previousOnload = window.onload || function noop(){};
		
		window.onload = function (){
			
			// Call any previously added onload callbacks
			previousOnload();
			
			// Add deactivator to each <a> element
			var elements = document.getElementsByTagName('a');
			for (var i=0; i<elements.length; i++){
				elements[i].ondragend = deactivate;
			}
			
			function deactivate(){
				var parent   = this.parentNode,
					position = this.nextSibling;
				parent.removeChild(this);
				// Using insertBefore instead of appendChild so that it is put at the right position among the siblings
				parent.insertBefore(this, position);
			}
		};
		
	})();
	
	
};