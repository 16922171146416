

/**
 * Run function only once.
 * 
 * @param  {Function} cb    
 * @param  {Object}   scope 
 * @return {Function}
 * @api public         
 */

module.exports = function(cb, scope) {
	var called = false;
	return function() {
		!called && cb.apply(scope, [].slice.call(arguments));
		called = true;
	};
};