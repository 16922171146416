/**
 * Youtube dependencies.
 */

var debug = require('debug')('video');
var events = require('events');
var lazy = require('lazy');


/**
 * Expose 'video'
 */

module.exports = lazy(function (el, options) {
	return new YoutubeVideo(el, options)
},
	'https://www.youtube.com/iframe_api',
	function (el, options) {
	var emitter = this;

	window.onYouTubeIframeAPIReady = function () {
		emitter.emit('loaded', el, options);
	};
});


/**
 * Youtube Video constructor.
 *
 * @param {Element} el
 * @param {Object} el
 * @api private
 *
 * @returns
 */


function YoutubeVideo(el, options) {
	var self = this;
	var video = el.querySelector('.resp-video');
	var overlay = el.querySelector('.video-overlay');

	//convert start time and end timestamps to seconds
	options.playerOptions.end = hmsToSeconds(options.playerOptions.end);
	options.playerOptions.start = hmsToSeconds(options.playerOptions.start);

	this.player = youtubePlayer(video, options.ytID, options.playerOptions , overlay);

	events(el, 'click .video-overlay', function (e) {
		self.play();
	});

}

/**
 * Calls Play on the YT Player
 *
 * @api public
 *
 */

YoutubeVideo.prototype.play = function () {
	this.player.playVideo();
}

/**
 * Factory Returns Youtube player
 *
 *
 * @param {Element} el
 * @param {String}  id
 * @param {Object} options
 * @api private
 *
 * @return {YT.Player}
 */

function youtubePlayer(el, id, options, overlay) {

	return new YT.Player(el, {
		width: '100%',
		height: '100%',
		videoId: id,
		events: {
			'onStateChange': function (e) {
				switch (e.data) {
					case YT.PlayerState.UNSTARTED:
						//no started
						break;
					case YT.PlayerState.ENDED:
						//ended
						break;
					case YT.PlayerState.PLAYING:
						//playing
						overlay.setAttribute('aria-hidden', true);
						break;
					case YT.PlayerState.PAUSED:
						//paused
						break;
					case YT.PlayerState.BUFFERING:
						//buffering
						break;
					case YT.PlayerState.CUED:
						//cued
						break;
				}
			}
		},
		playerVars: options || {}
	});
}




/**
 * Transforms timestamp in the format of MM:SS or HH:MM:SS to seconds
 *
 * @param {String} str
 * @api private
 *
 * @returns {Number} seconds
 */
function hmsToSeconds(str) {
	if(!str) {
		return 0;
	} else {
		var p = str.split(':');
		var s = 0;
		var m = 1;

		while (p.length > 0) {
			s += m * parseInt(p.pop(), 10);
			m *= 60;
		}
		return s;
	}



}