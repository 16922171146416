
/**
 * Schools and programs dependencies.
 */

var focus = require('focus');


/**
 * Expose programs section.
 */

module.exports = function(el) {

	/**
	 * Get focus event emitter.
	 * @type {Emitter}
	 */
	
	var section = focus(el);

	/**
	 * Elements to apply translate transform.
	 * @type {Element}
	 */
	
	var parallax = el.querySelector('#homepage-parallax');
	var list = el.querySelector('ul');


	/**
	 * Parallax ratio (refreshed on resize)
	 * @type {Number}
	 */
	
	
	var height = list.getBoundingClientRect().height;


	section.on('scroll', translate);
	// scroll.on('resize', function() {
	// 	var y = window.scrollY;
	// 	var rect = list.getBoundingClientRect();
	// 	height = rect.height;
	// 	var top = rect.top - (window.innerHeight / 2);
	// 	var bottom = top + height;

	// 	if(y < top) y = 0;
	// 	else if(y > bottom) y = bottom;
	// 	translate(y);
	// });


	/**
	 * Translate programs section according the scroll 
	 * position as well as the size of the schools list.
	 * 
	 * @param  {Number} y 
	 * @api private
	 */
	
	function translate(y) {
		var top = section.top;
		var diff = 30 * (y - top) / (section.bottom - top);
		parallax.setAttribute('style', 'transform:translate3d(0,-' + (height * diff / 100) * 1.5 + 'px,0);');

	}

};
