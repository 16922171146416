/**
 * Dots dependencies.
 */

var debug = require('debug')('section-dots');
var events = require('events');
var scroll = require('scroll');
var debounce = require('utility').debounceFunc;


/**
 * By default the first dot on the navigation
 * is active.
 * @type {Boolean}
 */

var first = true;


/**
 * Create DOM element from string.
 * 
 * @param  {String} str 
 * @return {Element}
 * @api private     
 */

function domify(str) {
	var div = document.createElement('div');
	div.innerHTML = str;
	return div.firstChild;
}


/**
 * Expose 'Dot'
 */

module.exports = Dots;

/**
 * Dot navigation constructor.
 * @api public
 */

function Dots() {
	var nav = domify('<div class="dot-navigation"><nav></nav></div>');
	var self = this;
	this.nav = nav.querySelector('nav');
	document.body.appendChild(nav);
	this.dotsContainer = document.body.querySelector('.dot-navigation');
	//find position on load and set dot nav
	events(window, 'resize', debounce(function () {
		self.resizeHandler();
	}, 300));
	//ipad is not losing hover state and dots are not hiding. adding event to force hide them

		events(document.querySelector('body'), 'touchend', debounce(function () {
			if (window.innerWidth >= 1024) {
				self.hideDots();
			}
		}, 300));
}


/**
 * Add dot in navigation.
 * 
 * @param {Number} id    
 * @param {String} label
 * @api public
 */

Dots.prototype.add = function(id, label) {
	var that = this;
	var link = domify('<a class="section-jump" href="#' + id + '" title="' + label + '"><span>' + label + '</span><i class="dot"></i></a>');
	if(first) link.classList.add('active');
	events(link, 'click', function() {
		that.show(id);
	});
	this.nav.appendChild(link);
	debug('add ' + id);
	first = false;
	return this;
};


/**
 * Show selected dot section.
 * 
 * @param  {Number} id    
 * @api public
 */

Dots.prototype.show = function(id) {
    var section = document.getElementById(id);
	if(section) {
		scroll(section.offsetTop - 120);
		debug('show #' + id);
	}
	return this;
};


/**
 * Select dot in navigation by index.
 * 
 * @param  {Number} id    
 * @api public
 */

Dots.prototype.select = function(id) {
	var dot = this.nav.children[id];
	var current = this.nav.querySelector('.active');
	if(current) current.classList.remove('active')
	dot.classList.add('active');
    debug('focus ' + current.getAttribute('href'));
	return this;
};

/**
 * Keeps track of the size of the dot navigation and browser.
 * Proceeds to center dots and show and hide them as needed
 *
 * @api private
 */
Dots.prototype.resizeHandler = function() {
	//grab the nav container element to hide.
	var navContainer = this.nav.offsetParent;

	//get heights, add padding of 90px to nav height
	var navHeight = this.nav.offsetHeight + 90;
	var winHeight = window.innerHeight
	if (navHeight > winHeight) {
		navContainer.classList.add('hidden');
	} else {
		navContainer.classList.remove('hidden');
	}

}

/**
 * Unfocus' the dot navigation on mobile so that we can hide it.
 *
 * @api private
 */
Dots.prototype.hideDots = function() {
	this.dotsContainer.blur();
}