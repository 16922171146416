
/**
 * Module dependencies.
 */

var Emitter = require('emitter');


/**
 * Debug filters.
 * @type {Array}
 */

var filters = [];


/**
 * Expose 'debug'
 */

module.exports = debug;


/**
 * Emit debug messages.
 *
 * A debug message is namespaces and can be printed
 * differently according the environment.
 *
 * Examples:
 *
 *   var debug = require('debug')('carousel');
 *   debug('slide');
 *
 * @param {String} name
 * @param {Boolean} exclude
 * @api public
 */

function debug(name, exclude) {
  return function(msg) {
    //if(!~filters.indexOf(name)) return;
    debug.emit('log', name, msg);
    debug.emit('log ' + name, msg);
  };
}


/**
 * Filter debug messages with namespace
 * wildcard (useful for developers and QA)
 *
 * @api public
 */

debug.filter = function() {
	filters = arguments;
};

Emitter(debug);

