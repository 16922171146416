
/**
 * Focus dependencies.
 */

var Emitter = require('emitter');
var listen = require('events');
var debug = require('debug')('focus');
var on = Emitter.prototype.on;
var debounce = require('utility').debounceFunc;


/**
 * Store top anchors for all focused sections.
 * @type {Array}
 */

var tops = [];


/**
 * Store bottom anchors to know where a section
 * should lose focus.
 * @type {Array}
 */

var bottoms = {};


/**
 * Store focused sections ids.
 * @type {Array}
 */

var sections = [];


/**
 * Current focused section id.
 * @type {String}
 */

var current;
var focused = false;


/**
 * Singleton event emitter;
 * @type {Emitter}
 */

var hub = new Emitter();


/**
 * Expose 'Focus'
 */

var focus = module.exports = function(el) {
  return new Focus(el);
};


/**
 * Returns the position relative to the viewport
 * height and scorll position.
 *
 * @param {Element} el
 * @return {Object} (id, top and bottom)
 * @api public
 */

focus.add = function(el) {
	var id = Math.random().toString(36).slice(2, 10);
	sections.push(id);
	return position(id, el);
};


/**
 * Get focus position of an element.
 *
 * @param {Element} el
 * @return {Object}
 * @api private
 */

function position(id, el) {
	var offset = el.offsetTop;
	var height = window.innerHeight / 2;
	var top = offset - (offset < height ? 0 : height);
	var bottom = bottoms[id] = el.getBoundingClientRect().height + top;
	tops.push(top);
	return {
		id: id,
		top: top,
		bottom: bottom
	};
}


/**
 * Focus constructor (global emitter façade_.
 * 
 * It exposes the section bounding rectangle (top, bottom) 
 * as well as its unique id. The id is used to catch focus
 * events trigerred by a singleton event emitter (this singleton is shared
 * between all instances of Focus)
 *
 * Example:
 *
 *   var focus = require('focus');
 *   var section = focus(el);
 *   section.on('focus', function(bool) {
 *     if(bool) //enter or leave section 
 *   })
 *   
 * @param {Element} el
 * @return {Function}
 * @api public
 */

function Focus(el) {
	var pos = focus.add(el);
	var that = this;
	this.id = pos.id;
	this.top = pos.top;
	this.bottom = pos.bottom;
	hub.on('resize', function() {
		var rect = position(that.id, el);
		that.top = rect.top;
		that.bottom = rect.bottom;
	});
}


/**
 * All instances share the same event emitter as
 * prototype.
 */

Focus.prototype = hub;


/**
 * Namespaced listener.
 *
 * For listener topics such as 'focus' and 'scroll'
 * this handler namespace the events with the focus id.
 *
 * Examples:
 *
 *   section.on('focus', function(bool) {
 *     // do something
 *   });
 * 
 * 
 * @param  {String}   topic 
 * @param  {Function} cb    
 * @api public         
 */

Focus.prototype.on = function(topic, cb) {
	if(topic === 'focus' || topic === 'scroll') {
		topic = topic + ' ' + this.id;
	}
	return on.call(hub, topic, cb);
};



/**
 * Reset section focus.
 */

listen(window, 'resize', debounce(function() {
	tops = [];
	bottom = {};
	hub.emit('resize');
}, 300));


/**
 * Emit scroll and focus event according
 * the window y position.
 * 
 * @api private
 */

function scroll() {
	var y = window.pageYOffset;
	var id = section(y);
	if(y < bottoms[id]) {
		hub.emit('scroll ' + id, y);
		if(id != current){
			focused = false;
			hub.emit('focus', current, false);
			hub.emit('focus ' + current, false);
		}
		if(!focused) {
			hub.emit('focus', id, true);
			hub.emit('focus ' + id, true);
			hub.emit('scroll ' + id, y);
			current = id;
			focused = true;
		}
	} else {
		if(focused) {
			hub.emit('focus', current, false);
			hub.emit('focus ' + current, false);

		}
		focused = false;
	}

	if(y < bottoms[Object.keys(bottoms)[0]]) {
		hub.emit('refocus', true, 'top');
	} else if(y > bottoms[Object.keys(bottoms)[Object.keys(bottoms).length-1]]) {
		hub.emit('refocus', true, 'bottom');
	}
	hub.emit('scroll', current, y);

}


/**
 * Listen scroll events (only once) and emit
 * scroll and/or focus events. Also set the focus on
 * a section at init.
 */

listen(window, 'scroll', scroll);
//will fire once all assets have loaded, and trigger a re-calc of offsets and scroll so focus listeners can be updated as well.
listen(window, 'load', function(e){
	//clear values stored.
	tops = [];
	bottom = {};
	hub.emit('resize');
	scroll();
});



/**
 * Return id of the actual focused element.
 * 
 * @param  {Number} y 
 * @return {String}   
 * @api private
 */

function section(y) {
	var id = -1;
	for(var i = 0, l = tops.length; i < l; i++) {
		if(y >= tops[i]) id = i;
		else break;
	}
	return sections[id];
};


