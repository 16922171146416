

/**
 * Add event listener on element.
 *
 * Events also provide an event delegation engine.
 *
 * Examples:
 *
 *   events(dom, 'click', cb);
 *   events(dom, 'click .btn', cb);
 *
 * @param {Element} el
 * @param {String} selector
 * @param {Function} cb
 * @api public
 */

module.exports = function(el, selector, cb) {
	var obj = parse(selector);
		el.addEventListener(obj.event, function(ev) {
		var query = obj.query;
		if(query) {
			var target = ev.target;
			var nodes = el.querySelectorAll(query);
			for(var c = 0, l = nodes.length; c < l; c++) {
				var node = nodes[c];
				if(node.contains(target) || node === target) cb(ev, node);
			}
		} else cb(ev, el);
	});
};


/**
 * Parse selector and return event name
 * as well as query selector if exist.
 *
 * @param {String} selector
 * @return {Object}
 * @api private
 */

function parse(selector) {
	// @note we should trim as well
  var arr = selector.split(' ');
  return {
  	event: arr.shift(),
  	query: arr.join(' ')
  };
}