/**
 * Header dependencies.
 */

var events = require('events');


var body = document.body;


// events(body.querySelector('.special-button'), 'blur', function() {
// 	console.log('BLUR');
// })

// events(body.querySelector('.special-button'), 'keydown', function(e) {
// 	if(e.keyCode === 9) {
// 		console.log('TAB');
// 		e.stopPropagation();
// 	}
// })
// 

/**
 * Expose 'header' component.
 * 
 * @param {Element} el
 * @api public
 */

module.exports = function(el) {
	var main = document.querySelector('.main');

	var type;

	/**
	 * Click on burger or school icon.
	 */
	
	events(el, 'click .burger-link', function(e, target) {
		toggle(type);
		type = target.classList.contains('left')
		  ? 'drawer-left'
		  : 'drawer-right';
		show(type);
		aria(target, 'expanded');
	});


	/**
	 * Close menu on overlay click and escape press.
	 */
	
	events(el, 'click .overlay', close);
	events(document, 'keyup', function(e) {
		if(e.keyCode === 27) close();
	});


	events(body.querySelector('.close-burger'), 'click', close);
	events(body.querySelector('.close-schools'), 'click', close);


	/**
	 * Hide/show header on scroll.
	 */
	
	var cache = 0;
	events(window, 'scroll', function() {
		var y = window.pageYOffset;
		if(y > 59 && !~body.className.indexOf('slide-drawer')) body.classList.add('navigation-slide');
		if(y < cache) body.classList.remove('navigation-slide');
		cache = y;
	});


	/**
	 * Show navigation menu.
	 * 
	 * @param  {String} type 
	 * @api private
	 */
	
	function show(type) {
		toggle(type, true);
		aria(main, 'hidden');
		aria(document.getElementById(type), 'hidden');
	}

	/**
	 * Hide navigation menu and show main content.
	 * 
	 * @api private
	 */
	
	function close() {
		var btn = el.querySelector('.burger-link[aria-expanded="true"]');
		aria(document.querySelector('.navigation-menu[aria-hidden="false"]'), 'hidden');
		aria(btn, 'expanded');
		aria(main, 'hidden');
		toggle(type);
		btn.blur();
	}

};


/**
 * Hide/show navigation menu and show/hide main content.
 * 
 * @api private
 */

function toggle(type, bool) {
  var name = 'slide-' + type;
  if(bool) body.classList.add(name);
  else body.classList.remove(name);
}


/**
 * Toggle aria attribute.
 * 
 * @param  {Element} el   
 * @param  {String} aria 
 * @api private      
 */

function aria(el, aria) {
	el.setAttribute('aria-' + aria, !(el.getAttribute('aria-' + aria) === 'true'))
}