/**
 * Expose 'now-page-hero'
 */

module.exports = function() {
    createClock();
};


/**
 * Create the Javascript clock that is displayed in the bottom left of the hero
 */

function createClock() {
    var calendarHook = document.getElementById('calendar-hook');
    var clockHook = document.getElementById('clock-hook');
    var monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var now = new Date();
    var h = now.getHours();
    var ampm = h >= 12 ? 'PM' : 'AM';
    var m = now.getMinutes();
    h = (h % 12) || 12;
    // h = h ? h : 12; // the hour '0' should be '12'
    m = m < 10 ? '0' + m : m;
    calendarHook.innerHTML = monthArray[now.getMonth()] + " " + now.getDate();
    clockHook.innerHTML =  h + ":" + m + ampm;
    
    setTimeout(createClock, 5000); //run the clock every 5 seconds 
}


