/**
 * Video dependencies.
 */

var debug = require('debug')('video');
var events = require('events');


/**
 * Expose 'video'
 */

module.exports = function(el, options) {
	if(options) {
        if (options.brightcove) {
            return new BrightcoveVideo(el, options);
        } else {
            return new Video(el, options);
        }
    }
};



/**
 * Video constructor.
 *
 * Should be able to play HTML5 video
 *
 *
 * @param {Element} el
 * @api public
 */

function Video(el, options) {
	var self = this;
	this.vidContainer = el;
	this.player = el.querySelector('video');
	var playIcon = el.querySelector('.icon-play');

	events(playIcon, 'click', function() {
		self.play();
	});

	if(options.autoplay === 'true') this.player.addEventListener('canplay', self.play());

	this.player.addEventListener('ended',function() {
		self._videoEnded();
	},false);
	this.player.addEventListener('playing',function() {
		self._videoPlaying();
	},false);
}


/**
 * Play video.
 *
 * @return {this}
 * @api public
 */

Video.prototype.play = function() {
	this.player.play();
	debug('play');
	return this;
};


/**
 * Pause video.
 *
 * @return {this}
 * @api public
 */

Video.prototype.pause = function() {
	this.player.pause();
	debug('pause');
	return this;
};


/**
 * video ended.
 *
 * @api private
 */

Video.prototype._videoEnded = function() {
	//disable controls again
	this.player.controls = false;
	//show play icon again
	this.vidContainer.querySelector('.video-overlay').setAttribute('aria-hidden', false);
	debug('video ended');
}

/**
 * video playing
 *
 * @api private
 */

Video.prototype._videoPlaying = function () {
	var enableControls = this.player.classList.contains('controls-enabled') && !this.vidContainer.classList.contains('background-video') ? true : false;
	if(enableControls){
		//show controls if set to be shown
		this.player.controls = true;
	}
	//hide play icon as we are playing
	this.vidContainer.querySelector('.video-overlay').setAttribute('aria-hidden', true);
	debug('video playing');
}


/**
 * Brightcove Video Contstructor
 *
 * brightcove video uses video.js to control it's videos, so we'll do the same, and lazy load it's script.
 *
 */

function BrightcoveVideo(el, options) {
	var self = this;
	this.player;
	this.videoContainer = el;

	if(options.brightcove.noPoster){
		loadVideo();
	} else {
		var posterImgs = el.querySelectorAll('.video-overlay img');
		var completeArr = [];
		var loadCount = 0;
		var b = setInterval(function(){
			for (var i = 0; i < posterImgs.length; i++){
				if (posterImgs[i].complete && completeArr.indexOf(posterImgs[i]) === -1) {
					completeArr.push(posterImgs[i]);
					loadCount++;
				}
				if(loadCount === (posterImgs.length)) {
					clearInterval(b);
					loadVideo();
				}
			}
		}, 200);
	}

	//function that loads the video content for brightcove.
	function loadVideo() {
		var videoEle = self.videoContainer.querySelector('.bright-video');
		var vidOverlay = self.videoContainer.querySelector('.video-overlay');
		videoEle.setAttribute('data-video-id', options.brightcove.videoId);
		videoEle.setAttribute('data-account', options.brightcove.accountId);
		videoEle.setAttribute('data-player', options.brightcove.playerId);
		bc(videoEle);
		self.player = videojs(self.videoContainer.querySelector('.bright-video').getAttribute('id'));
		if (options.autoplay === 'true') self.player.ready(function () {
			self.player.play();
			//to keep in line with current player behavoir on hero's.
			if (self.videoContainer.querySelector('.video-overlay')) {
				self.player.on('timeupdate', function() {
					if(self.player.currentTime() > 0 && vidOverlay.getAttribute('aria-hidden') != 'true'){
						vidOverlay.setAttribute('aria-hidden', true);
					}
				});
			}
		});
	}
}



/**
 * Brightcove Play video.
 *
 * @return {this}
 * @api public
 */

BrightcoveVideo.prototype.play = function() {
	this.player.play();
	return this;

};


/**
 * Brightcove Pause video.
 *
 * @return {this}
 * @api public
 */

BrightcoveVideo.prototype.pause = function() {

	this.player.pause();
	return this;

};
