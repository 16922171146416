
/**
 * Send form data in a post request
 * with a given url.
 *
 * @param {Element} form
 * @param  {String} url 
 * @param {Function} cb
 * @return {Promise} 
 * @api public    
 */

module.exports = function(form, url, cb) {
	var data = new FormData(form);
	var req = new XMLHttpRequest();
	req.open('POST', url, true);
	req.onreadystatechange = function () {
		if(req.readyState === 4) {
			if(req.status === 200) cb(null, JSON.parse(req.responseText));
			else cb(new Error('student not found'));
		} 

	};
	req.send(data);
};