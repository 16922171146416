/**
 * Lazy loading dependencies.
 */

var Emitter = require('emitter');
var debug = require('debug')('lazy');


/**
 * Lazy load a script and execute function
 * as soon as the script is loaded.
 *
 * An optional condition function can be passed to manually
 * trigger a loaded event on the script (see example).
 *
 * Examples
 *
 *   lazy(cb, 'http://youtube.com/sdhsd', function() {
 *     var emitter = this;
 *     setTimeout(function() {
 *       emitter.emit('loaded');
 *     }, 5000);
 *   });
 * 
 * @param  {Function} cb  
 * @param  {String}   url 
 * @param  {Function}   condition 
 * @return {Function} 
 * @api public
 */

module.exports = function(cb, url, condition) {
  var loaded = false;
  var requested = false
  var progress = new Emitter();

  return function(el, options) {
    progress.on('loaded', function() {
      cb(el, options);
    });
    if(loaded) progress.emit('loaded');
    else if(!requested) {
      requested = true;
      if(url === '' && options.lazyURL || url === undefined && options.lazyURL){
        debug('Setting lazy script via options.');
        url = options.lazyURL;
      } else  if(!url) {
        debug('Lazy called with no script url and no script url passed via the options');
        return false;
      }

      script(url, function() {
        if(condition) condition.call(progress, el, options);
        else progress.emit('loaded');
        debug('loaded ' + url);
        loaded = true;
      });
    }
  };
};


/**
 * Create and load a script with given url.
 *
 * @param {String} url
 * @param {Function} cb
 * @api private
 */

function script(url, cb) {
  var script = document.createElement('script');
  script.onload = cb;
  script.src = url;
  document.head.appendChild(script);
}