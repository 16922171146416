/**
 * Modal dependencies.
 */

var html = require('./modal.html')
var material = require('material')


/**
 * Expose 'gallery-modal'
 *
 * @param {Element} parent (element to append to)
 * @api public
 */

module.exports = function(parent, length) {

  var that = {};

  var body = document.body;

  /**
   * Modal presentation.
   */
  
  var ui = material(html, {hidden: true, index: -1, length: length})
    .build();


  // seems like material append does not work
  parent.appendChild(ui.el);

  /**
   * Update gallery modal without showing it.
   * 
   * @param  {Element} img 
   * @api public   
   */
  
  that.update = function(img, index) {
  	ui.set('source', img.getAttribute('src'));
  	ui.set('alt', img.getAttribute('alt'));
    ui.set('index', index);
  };


  /**
   * Show image with given source and alternative text.
   * 
   * @param  {String} src 
   * @param  {String} alt  
   * @api public   
   */
  
  that.show = function(index) {
  	ui.set('hidden', false);
    ui.set('index', index);
    body.classList.add('modal-show');
  };


  /**
   * Close gallery modal.
   *
   * A modal gallery should be closed when the close button
   * is clicked or when the gallery lose the focus.
   * 
   * @api public
   */
  
  that.close = function() {
  	ui.set('hidden', true);
    body.classList.remove('modal-show');
  };


  ui.listen('click .close', that.close);

  return that;
};