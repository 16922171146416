/**
 * Panorama dependencies.
 */

var mixin = require('utility').mixin;
var frame = window.requestAnimationFrame
  || window.mozRequestAnimationFrame
  || window.webkitRequestAnimationFrame
  || window.msRequestAnimationFrame;
var cancel = window.cancelAnimationFrame
  || window.mozCancelAnimationFrame;


/**
 * Expose 'Panorama'
 */

module.exports = function(el, options) {
  return new Panorama(el, options);
};


/**
 * Create map with given latitude,
 * longitude and options.
 *
 * @param  {Element} el 
 * @param  {Object} options 
 * @api public
 */

function Panorama(el, options) {
	
	this.view = new google.maps.StreetViewPanorama(el, mixin({
		pov : {
		    heading: 350,
		    pitch: -2
		},
		linksControl: false,
		panControl: false,
		addressControl: false,
		zoomControl: true,
		fullScreenControl: true,
		enableCloseButton: false//,
		// disableDefaultUI: true
	}, options));
	this.view.setVisible(true);
};


/**
 * Update map with given latitude and
 * longitude.
 *
 * @param  {Number} lat 
 * @param  {Number} lng 
 * @api public
 */

Panorama.prototype.position = function(lat, lng) {
	this.view.setPosition({
		lat: Number(lat),
		lng: Number(lng)
	});
	return this;
};

Panorama.prototype.pov = function(pov) {
	this.view.setPov(pov);
	return this;
};


/**
 * Automatically pan the panorama.
 *
 * @param  {Boolean} bool 
 * @api public     
 */

Panorama.prototype.panning = function(bool) {
	var that = this;
	cancel(this.id);
	if(bool) {
		var pan = function() {
			var pov = that.view.getPov();
			pov.heading += 0.03;
			that.view.setPov(pov);
			that.id = frame(pan);
		};
		this.id = frame(pan);
	}
	return this;
};



Panorama.prototype.refresh = function() {
	this.view.setPov(this.view.getPov());
	return this;
};
